import React from 'react';
import {Link} from 'react-router-dom';
import {Element, Link as Scroll} from 'react-scroll';
import Dropdown from 'rc-dropdown';
import 'rc-dropdown/assets/index.css';
import MenuIcon from '@material-ui/icons/Menu';

import NavMenu from '../../../../../components/NavMenu';

import logo from '../../../../../assets/logo-cobre.png';

import './styles.css';

const Principal = () => {
  const appToken = localStorage.getItem('apptoken');

  return (
    <Element name="principal" className="principal">
      <div className="principal-content">
        <div className="principal-logo-container">
          <img src={logo} className="principal-logo" alt="Paulo Magalhães" />

          <h2 className="title">Paulo Magalhães</h2>
          <h1 className="title">Advogado Criminalista</h1>

          <div className="principal-responsive-menu">
            <Dropdown
              arrow
              trigger={['click']}
              overlay={NavMenu}
              animation="slide-up"
            >
              <button
                onClick={null}
                className="principal-menu-btn"
                type="button"
              >
                <MenuIcon fontSize="large" />
              </button>
            </Dropdown>
          </div>
        </div>

        <div className="principal-menu">
          <ul dir="rtl">
            <li>
              <Scroll to="sobre" smooth>
                Sobre
              </Scroll>
            </li>

            <li>
              <Scroll to="artigos" smooth>
                Artigos
              </Scroll>
            </li>

            <li>
              <Scroll to="servicos" smooth>
                Serviços
              </Scroll>
            </li>

            <li>
              <Scroll to="contato" smooth>
                Contato
              </Scroll>
            </li>

            <li>
              <Scroll to="mapa" smooth>
                Endereço
              </Scroll>
            </li>

            <li>
              {appToken ? (
                <Link to="/logout">Desconectar</Link>
              ) : (
                <Link to="/login">Área do Cliente</Link>
              )}
            </li>
          </ul>
        </div>
      </div>
    </Element>
  );
};

export default Principal;
